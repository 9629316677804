import React, {Component} from 'react'

class Conversations extends Component {
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    window.open(
      `${process.env.REACT_APP_FRONTEND3_URL}/app/conversations?token=${user.access_token}`,
      '_blank'
    );
  }

  render() {       
    return (
          <div>
          </div>
          )
      }
}


export default Conversations;
