import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Col } from "react-bootstrap";
import PropertyListingForm from "../../_components/PropertyListingForm/PropertyListingForm";
import { propertyActions } from "../../_actions/propertyActions";
import { mapListingToEdit } from "../../_helpers/mappers";
import { ListingConfirmationModalWrapper } from "../../_components/Modals/ListingConfirmationModalWrapper";
import { listingActions } from "../../_actions/listingActions";

export class PropertyListingNewPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      showModal: false
    };
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  submit = values => {
    this.setState({ showModal: false, values });
    this.props.showConfirmation(values);
    document.getElementById("gotop").focus()
  };

  handleCancelation = () => {
    this.props.dismissConfirmationDialog();
  };
  handleSubmission = () => {
    this.props.saveNewListing(mapListingToEdit(this.state.values));
  };
  render() {
    const { currentListing, listingConfirmationDialogOpen } = this.props;
    return (
      <Col md={12}>
      <input Style="opacity:0;" name="gotop" observation="*Opcional" id="gotop" class="form-control__white form-control" value="sdfdsf" />
        <PropertyListingForm
          user={this.props.user}
          new={true}
          onSubmit={this.submit}
          onDraftSubmit={this.toggleModal}
        />
        {listingConfirmationDialogOpen && currentListing && (
          <ListingConfirmationModalWrapper
            onSubmit={this.handleSubmission}
            onCancel={this.handleCancelation}
            onHide={this.handleCancelation}
            listing={currentListing}
            show={true}
          />
        )}
        <Modal
          className="form-modal"
          show={this.state.showModal}
          onHide={this.toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cadastrar novo anúncio</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            Você ainda não preencheu todos os campos necessários para a
            publicação do seu anúncio. Deseja salvar um rascunho e finalizar o
            cadastro posteriormente?
          </Modal.Body>

          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="btn-primary__cancel"
              onClick={this.toggleModal}
            >
              Cancelar
            </Button>
            <Button
              bsStyle="primary"
              className="btn_primary__confirm"
              onClick={this.submit}
            >
              Salvar rascunho
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveNewListing: values => dispatch(propertyActions.register(values)),
  showConfirmation: values =>
    dispatch(listingActions.showListingConfirmationDialog(values)),
  dismissConfirmationDialog: () =>
    dispatch(listingActions.hideListingConfirmationDialog())
});
const mapStateToProps = state => ({
  user: state.authentication.user.data,
  listingConfirmationDialogOpen: state.properties.listingConfirmationDialogOpen,
  currentListing: state.properties.currentListing
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyListingNewPage);
