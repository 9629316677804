import React from "react";
import { Route, Redirect } from "react-router-dom";
import queryString from "query-string";

const shouldAllowPassage = props => {
  const token = queryString.parse(props.location.search).token;
  if (token) {
    const user = { access_token: token };
    localStorage.setItem("user", JSON.stringify(user));
  }
  return localStorage.getItem("user") || token;
};
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      shouldAllowPassage(props) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
