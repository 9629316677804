import React from 'react';
import Sidebar from 'react-sidebar';
import styled from 'styled-components';
import { userActions } from '../../_actions/userActions';
import { history } from '../../_helpers/history';
const Wrapper = styled.div`
  padding: 2.5em;
`;

function getMQL(){
  return  window.matchMedia(`(min-width: 800px)`);
}
class SideNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked:  getMQL().matches,
      sidebarOpen: false
    };
  }
  
  componentWillMount() {
    getMQL().addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    getMQL().removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open });
  };

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: getMQL().matches, sidebarOpen: false });
  };

  handleToggle = () => {
    this.setState({
      sidebarDocked:  getMQL().matches,
      sidebarOpen: !this.state.sidebarOpen
    });
  };
  handleExit = () => {
     userActions.logout()
  };
  handleProfileRequest = () => {
    history.push('/profile')
  };
  render() {
    return (
      <Sidebar
        sidebarClassName={'sidecontent'}
        rootId="page-wrap"
        transitions={true}
      >
        <Wrapper>{this.props.children}</Wrapper>
      </Sidebar>
    );
  }
}

export default SideNavigation;
