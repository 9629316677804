import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './App.css'; 
import AppRouter from './_routers/AppRouter';
import { history } from './_helpers/history';
import configureStore from './_store/configureStore';
import { Provider } from 'react-redux';
  import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { ToastContainer } from 'react-toastify';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
const bugsnagClient = bugsnag('bdf05163f2d65f08815f36affbe1f0c9')
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <div>
      <ToastContainer />
      <Router history={history}>
        <AppRouter />
      </Router>
    </div>
  </Provider>
);

unregisterServiceWorker();
const rootElement = document.getElementById('root');
ReactDOM.render(<ErrorBoundary> <App /> </ErrorBoundary>, rootElement);

if (module.hot) {
  module.hot.accept('./_routers/AppRouter', () => {
    ReactDOM.render(<App />, rootElement);
  });
}
