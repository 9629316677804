import React, {Component} from 'react'


class ContractsNew extends Component {
  componentDidMount() {
    //let user = JSON.parse(localStorage.getItem('user'));
    //window.location.replace(
    //  `${process.env.REACT_APP_FRONTEND3_URL}/contracts/new?token=${user.access_token}`
    //);
    window.open(
      'https://forms.gle/LMM2xJWbCrVC9zBHA',
      '_blank'
    );
  }

  render() {       
    return (
          <div>
          </div>
          )
      }
}

export default ContractsNew;
