import { propertyConstants } from '../_constants/propertyConstants';
import { propertyService } from '../_services/propertyService';
import { listingService } from '../_services/listingService';
import { alertActions } from './alertActions';
import { history } from '../_helpers/history';
import { paymentConstants } from '../_constants/paymentConstants';
import { paymentService } from '../_services/paymentService';
import { listingConstants } from '../_constants/listingConstants';
import { billingActions } from './billingActions';
import moment from 'moment';
import {showSharePropertyDialog} from "../_scenes/Dashboard/Dialogs/AlertDialogs"

export const propertyActions = {
  register,
  update,
  clearPropertyToEdit,
  findById,
  findAll,
  findMyPropertyListings,
  filterPropertiesByText,
  filterPropertiesWithFeatures,
  setAnalyticsGranularity,
  hideModals,
  sortByPaymentStatus,
  getSummary,
  updatePayment,
  updatePaymentAndNotifyTenant,
  createTransaction,
  deleteTransaction,
  enableListing,
  disableListing,
  reviewBillingMethod,
  findAllSortingBy,
  filterApiPropertiesById
};

function findAllSortingBy(field, order = 'asc') {
  return dispatch => {
    dispatch(request());
    propertyService.findAllSortingBy(field, order).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível executar a operação`));
      }
    );
  };

  function request() {
    return { type: propertyConstants.PROPERTIES_GETALL_REQUEST };
  }
  function success(properties) {
    return { type: propertyConstants.PROPERTIES_GETALL_SUCCESS, properties };
  }
  function failure(error) {
    return { type: propertyConstants.PROPERTIES_GETALL_FAILURE, error };
  }
}
function reviewBillingMethod(contractId, body, propertyId) {
  return dispatch => {
    dispatch(request(contractId));
    propertyService.reviewBillingMethod(contractId, body).then(
      response => {
        dispatch(success(contractId));
        dispatch(propertyActions.findById(propertyId));
        dispatch(hideModals());
      },
      error => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            `Não foi possível atualizar o método de cobrança, contacte o administrador e informe a referência de contrato ${contractId} `
          )
        );
      }
    );
  };
  function request(id) {
    return { type: propertyConstants.REVIEW_BILLING_REQUEST, id };
  }
  function success(contractId) {
    return { type: propertyConstants.REVIEW_BILLING_SUCCESS, contractId };
  }
  function failure(error) {
    return { type: propertyConstants.HIDE_MODALS };
  }
}
function enableListing(id) {
  return dispatch => {
    dispatch(request(id));
    listingService.enableListing(id).then(
      response => {
        dispatch(success(response.data));
        dispatch(alertActions.showMessageDialog('Pronto', 'Seu anúncio foi ativado com sucesso! Assim que aprovado, será espalhado pela internet automaticamente.', 'success'));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.showMessageDialog('Erro!', 'Não foi possível atualizar o anúncio ' + id, 'error'));
      }
    );
  };
  function request(id) {
    return { type: listingConstants.LISTING_CHANGE_REQUEST, id };
  }
  function success(listing) {
    return { type: listingConstants.LISTING_CHANGE_SUCCESS, listing };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_CHANGE_ERROR, error };
  }
}

function disableListing(id) {
  return dispatch => {
    dispatch(request(id));
    listingService.disableListing(id).then(
      response => {
        dispatch(success(response.data));
        dispatch(alertActions.showMessageDialog('Pronto', 'Seu anúncio foi desativado, ele não está mais disponível para visualização na internet. Para mais soluções de emissão de contratos e boletos recorrentes, clique no botão "Encontrei meu inquilino" no seu anúncio.', 'success'));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.showMessageDialog('Erro!', 'Não foi possível atualizar o anúncio ' + id, 'error'));
      }
    );
  };
  function request(id) {
    return { type: listingConstants.LISTING_CHANGE_REQUEST, id };
  }
  function success(listing) {
    return { type: listingConstants.LISTING_CHANGE_SUCCESS, listing };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_CHANGE_ERROR, error };
  }
}

function hideModals() {
  return dispatch => {
    dispatch(request());
  };
  function request(body) {
    return { type: propertyConstants.HIDE_MODALS, body };
  }
}
function sortByPaymentStatus(status, items) {
  return dispatch => {
    dispatch(request(items, status));
  };
  function request(itemsToSort, currentSortStatus) {
    return {
      type: propertyConstants.PROPERTIES_SORT_BY_STATUS,
      itemsToSort,
      currentSortStatus
    };
  }
}
function filterPropertiesByText(searchText) {
  return dispatch => {
    dispatch(request(searchText));
  };
  function request(searchText) {
    return { type: propertyConstants.FILTER_PROPERTY_BY_TEXT, searchText };
  }
}
function filterApiPropertiesById(searchText) {
  return dispatch => {
    dispatch(request(searchText));
    propertyService.searchPropertyLike('id', searchText).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível executar a operação`));
      }
    );
  };
  function failure(error) {
    return { type: propertyConstants.PROPERTIES_GETALL_FAILURE, error };
  }
  function success(properties) {
    return { type: propertyConstants.PROPERTIES_GETALL_SUCCESS, properties };
  }
  function request(searchText) {
    return { type: propertyConstants.FILTER_PROPERTY_BY_TEXT, searchText };
  }
}
function filterPropertiesWithFeatures(filters) {
  return dispatch => {
    dispatch(request(filters));
  };
  function request(filters) {
    return {
      type: propertyConstants.FILTER_RENTED_PROPERTIES,
      filters
    };
  }
}

function setAnalyticsGranularity(analyticsGranularity) {
  return dispatch => {
    dispatch(request(analyticsGranularity));
  };
  function request(analyticsGranularity) {
    return {
      type: propertyConstants.ANALYTICS_GRANULARITY_CHANGE,
      analyticsGranularity
    };
  }
}
function findMyPropertyListings() {
  return dispatch => {
    dispatch(request());
    propertyService.getAll().then(
      response => {
        const filteredListings = response.data.filter(property => {
          return property.listing;
        });
        dispatch(success(filteredListings));
      },
      error => dispatch(failure(error))
    );
  };
  function request() {
    return { type: propertyConstants.PROPERTIES_GETALL_REQUEST };
  }
  function success(listings) {
    return { type: propertyConstants.GETALL_PROPERTY_LISTINGS, listings };
  }
  function failure(error) {
    return { type: propertyConstants.PROPERTIES_GETALL_FAILURE, error };
  }
}

function findAll(url) {
  return dispatch => {
    dispatch(request());
    propertyService.getAll(url).then(
      response => {
        console.log(response)
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível encontrar imóveis`));
      }
    );
  };
  function request() {
    return { type: propertyConstants.PROPERTIES_GETALL_REQUEST };
  }
  function success(properties) {
    return { type: propertyConstants.PROPERTIES_GETALL_SUCCESS, properties };
  }
  function failure(error) {
    return { type: propertyConstants.PROPERTIES_GETALL_FAILURE, error };
  }
}
function createTransaction(paymentId, body) {
  return dispatch => {
    dispatch(request(paymentId));
    paymentService.createTransaction(paymentId, body, true).then(
      response => {
        dispatch(success(response.data));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(id) {
    return { type: paymentConstants.CREATE_TRANSACTION_REQUEST, id };
  }
  function success(body) {
    return { type: paymentConstants.CREATE_TRANSACTION_SUCCESS, body };
  }
  function failure(error) {
    return { type: paymentConstants.CREATE_TRANSACTION_ERROR, error };
  }
}

function deleteTransaction(transactionId, paymentId) {
  return dispatch => {
    dispatch(request(transactionId));
    paymentService.deleteTransaction(transactionId).then(
      response => {
        dispatch(success({ transactionId, paymentId }));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(id) {
    return { type: paymentConstants.DELETE_TRANSACTION_REQUEST, id };
  }
  function success(body) {
    return { type: paymentConstants.DELETE_TRANSACTION_SUCCESS, body };
  }
  function failure(error) {
    return { type: paymentConstants.DELETE_TRANSACTION_ERROR, error };
  }
}

function updateTransaction(paymentId, body) {
  return dispatch => {
    dispatch(request(paymentId));
    paymentService.updateTransaction(paymentId, body, true).then(
      response => {
        dispatch(success(response.data));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(id) {
    return { type: paymentConstants.UPDATE_TRANSACTION_REQUEST, id };
  }
  function success(body) {
    return { type: paymentConstants.UPDATE_TRANSACTION_SUCCESS, body };
  }
  function failure(error) {
    return { type: paymentConstants.UPDATE_TRANSACTION_ERROR, error };
  }
}
function updatePaymentAndNotifyTenant(updateValues, oldValues) {
  return dispatch => {
    dispatch(request(oldValues.id));
    paymentService.updatePayment(oldValues.id, updateValues, false).then(
      response => {
        dispatch(propertyActions.hideModals());
        dispatch(billingActions.findAllPayments());
        dispatch(getSummary());
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(currentDateSummary) {
    return {
      type: paymentConstants.UPDATE_PAYMENT_REQUEST,
      currentDateSummary
    };
  }
  // eslint-disable-next-line no-unused-vars
  function success(body) {
    return { type: paymentConstants.UPDATE_PAYMENT_SUCCESS, body };
  }
  function failure(error) {
    return { type: paymentConstants.UPDATE_PAYMENT_ERROR, error };
  }
}

function updatePayment(newPayment, oldPayment, markAsPaid) {
  return dispatch => {
    dispatch(request(oldPayment.id));
    if (!markAsPaid && newPayment && newPayment.transactions) {
      newPayment.transactions.map(transaction => {
        transaction.amount_in_cents = parseInt(
          transaction.amount_in_cents ? transaction.amount_in_cents : 0,
          0
        );
        if (!transaction.name) {
          transaction.name = transaction.transaction_type;
        }
        if (!transaction.id && transaction.amount_in_cents > 0) {
          return dispatch(createTransaction(oldPayment.id, transaction));
        } else {
          let oldTransaction = oldPayment.transactions.find(
            oldT => oldT.id === transaction.id
          );
          if (
            oldTransaction &&
            (oldTransaction.amount_in_cents !== transaction.amount_in_cents ||
              oldTransaction.name !== transaction.name)
          ) {
            return dispatch(updateTransaction(transaction.id, transaction));
          } else {
            return dispatch(success({}));
          }
        }
      });
    }
    delete newPayment['transactions'];
    paymentService.updatePayment(oldPayment.id, newPayment, markAsPaid).then(
      response => {
        if (!markAsPaid) {
          dispatch(requestNewBoletoNotification(oldPayment.id));
        }
        dispatch(hideModals());
        dispatch(billingActions.findAllPayments());
        dispatch(getSummary());
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(currentDateSummary) {
    return {
      type: paymentConstants.UPDATE_PAYMENT_REQUEST,
      currentDateSummary
    };
  }
  function success(payment) {
    return { type: paymentConstants.UPDATE_PAYMENT_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.UPDATE_PAYMENT_ERROR, error };
  }
}

function requestNewBoletoNotification(paymentId) {
  return dispatch => {
    dispatch(request(paymentId));
    paymentService.requestNewBoletoNotification(paymentId).then(
      response => {
        dispatch(success(response.data));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(id) {
    return {
      type: paymentConstants.NEW_BOLETO_NOTIFICATION_REQUEST,
      id
    };
  }
  function success(body) {
    return { type: paymentConstants.NEW_BOLETO_NOTIFICATION_SUCCESS, body };
  }
  function failure(error) {
    return { type: paymentConstants.NEW_BOLETO_NOTIFICATION_ERROR, error };
  }
}

function getSummary(current,offset) {
  let currentDateSummary = moment(current).add(offset, 'month');
  return dispatch => {
    dispatch(request(currentDateSummary));
    paymentService.getSummary(currentDateSummary.month(), currentDateSummary.year()).then(
      response => {
        dispatch(success(response.data));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(currentDateSummary) {
    return {
      type: paymentConstants.GET_BILLING_SUMMARY_REQUEST,
      currentDateSummary
    };
  }
  function success(summaryData) {
    return { type: paymentConstants.GET_SUMMARY_SUCCESS, summaryData };
  }
  function failure(error) {
    return { type: paymentConstants.GET_SUMMARY_FAILURE, error };
  }
}

function findById(propertyId) {
  return dispatch => {
    dispatch(request(propertyId));
    propertyService.getById(propertyId).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            `Não foi possível localizar este imóvel. id=${propertyId}`
          )
        );
      }
    );
  };
  function request(property) {
    return { type: propertyConstants.GET_PROPERTY_REQUEST, property };
  }
  function success(property) {
    return { type: propertyConstants.GET_PROPERTY_SUCCESS, property };
  }
  function failure(error) {
    return { type: propertyConstants.GET_PROPERTY_FAILURE, error };
  }
}
function register(property) {
  return dispatch => {
    dispatch(request(property));

    propertyService.register(property).then(
      response => {
        dispatch(success());
        history.push('/dashboard');
        dispatch(alertActions.success('Imóvel cadastrado com sucesso!'));
        if(response.data.listing !== null ) {
          showSharePropertyDialog(response.data.id)
        }
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            'Não foi possível cadastrar o imóvel. Verifique as informações e tente novamente.'
          )
        );
      }
    );
  };

  function request(property) {
    return { type: propertyConstants.REGISTER_REQUEST, property };
  }
  function success(property) {
    return { type: propertyConstants.REGISTER_SUCCESS, property };
  }
  function failure(error) {
    return { type: propertyConstants.REGISTER_FAILURE, error };
  }
}

function update(property) {
  return dispatch => {
    dispatch(request(property));

    propertyService.update(property).then(
      response => {
        dispatch(success(response.data));
        history.push('/dashboard');
        dispatch(alertActions.success('Imóvel atualizado com sucesso!'));
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            'Não foi possível atualizar o imóvel. Verifique as informações e tente novamente.'
          )
        );
      }
    );
  };

  function request(property) {
    return { type: propertyConstants.REGISTER_REQUEST, property };
  }
  function success(property) {
    return { type: propertyConstants.REGISTER_SUCCESS, property };
  }
  function failure(error) {
    return { type: propertyConstants.REGISTER_FAILURE, error };
  }
}

function clearPropertyToEdit() {
  return dispatch => {
    return dispatch({
      type: propertyConstants.GET_TO_EDIT_SUCCESS,
      payload: null
    });
  };
}
