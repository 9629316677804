import React from "react";
import { connect } from "react-redux";
import { Col, Grid, Row } from "react-bootstrap";
import UserDataFrom from "../../_components/UserDataForm/UserDataForm";
import { userActions } from "../../_actions/userActions";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { SimplePanel } from "../../_components/SimplePanel";
export class ProfilePage extends React.Component {
  componentWillMount() {
    if(!this.props.user){
      this.props.dispatch(userActions.getSelf());
    }
  }

  submit = values => {
    const { dispatch } = this.props;
    dispatch(userActions.updateSelf(values));
  };

  render() {
    return (
      <div>
        <Grid className="signup ">
          <Row className="main">
            {this.props.loading ? (
              <SimplePanel>
                <LoadingMessage title="Carregando Perfil..." />
              </SimplePanel>
            ) : (
              <Col md={12}>
                <UserDataFrom
                  loading={this.props.profileLoading}
                  onSubmit={this.submit}
                  hideRegisterFields
                  initialValues={this.props.user}
                />
              </Col>
            )}
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.authentication.profileLoading || state.registration.loading,
    user: state.authentication.user.data
  };
}

export default connect(mapStateToProps)(ProfilePage);
