import React from 'react';

export class SignupPage extends React.Component {

  componentDidMount() {
    window.location.replace(
        `${process.env.REACT_APP_FRONTEND3_URL}/users/sign_up`
      );
  }

  render() {
    return (
    <div>
    </div>
    );
  }
}

export default SignupPage;
