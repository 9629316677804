import { userConstants } from "../_constants/userConstants";
import { userService } from "../_services/userService";
import { alertActions } from "./alertActions";
import { history } from "../_helpers/history";

export const userActions = {
  login,
  facebookLogin,
  googleLogin,
  logout,
  register,
  changePasswordRequest,
  changePassword,
  getSelf,
  getUserById,
  updateSelf,
  loginByToken
};
function loginByToken(token) {
  const user = { access_token: token };
  localStorage.setItem("user", JSON.stringify(user));

  return dispatch => {
    dispatch(request({ email: "token_request" }));
    userService
      .getSelf()
      .then(
        user => {
          dispatch(success(user.data));
        },
        error => dispatch(failure(error.toString()))
      )
      .then(() => history.push("/dashboard"))
      .catch(error => {
        dispatch(failure("Usuário ou senha inválidos."));
        dispatch(alertActions.error("Usuário ou senha inválidos."));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function login(email, password) {
  return dispatch => {
    dispatch(request({ email }));

    userService
      .login(email, password)
      .then(user => dispatch(success(user)))
      .then(() => dispatch(getSelf()))
      .then(() => history.push("/dashboard"))
      .catch(error => {
        dispatch(failure("Usuário ou senha inválidos."));
        dispatch(alertActions.error("Usuário ou senha inválidos."));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function facebookLogin(fbData) {
  return dispatch => {
    dispatch(request({ fbData }));

    userService
      .facebookLogin(fbData.accessToken)
      .then(user => {
        dispatch(success(user));
      })
      .then(() => dispatch(getSelf()))
      .then(() => history.push("/dashboard"))
      .catch(error => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        // history.push('/signup');

        // dispatch(change('UserDataForm', 'full_name', fbData.name));
        // dispatch(change('UserDataForm', 'email', fbData.email));
        // dispatch(change('UserDataForm', 'email_confirmation', fbData.email));
        // dispatch(change('UserDataForm', 'fb_id', fbData.id));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function googleLogin(googleData) {
  return dispatch => {
    dispatch(request({ googleData }));

    userService
      .googleLogin(googleData.tokenId)
      .then(user => {
        dispatch(success(user));
      })
      .then(() => dispatch(getSelf()))
      .then(() => history.push("/dashboard"))
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        // history.push('/signup');
        // dispatch(failure(error));
        // dispatch(
        //   change('UserDataForm', 'full_name', googleData.profileObj.name)
        // );
        // dispatch(change('UserDataForm', 'email', googleData.profileObj.email));
        // dispatch(
        //   change(
        //     'UserDataForm',
        //     'email_confirmation',
        //     googleData.profileObj.email
        //   )
        // );
        // dispatch(
        //   change('UserDataForm', 'google_id', googleData.profileObj.googleId)
        // );
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  history.push("/login");
  return { type: userConstants.LOGOUT };
}

function changePasswordRequest(email) {
  return dispatch => {
    dispatch(request({ email }));

    userService.changePasswordRequest(email).then(
      response => {
        dispatch(success());
        dispatch(
          alertActions.success(
            "Link de alteração de senha enviado com sucesso! Verifique o seu e-mail dentro de alguns instantes."
          )
        );
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            "Não foi possível enviar o link de alteração de senha. Verifique o e-mail digitado e tente novamente."
          )
        );
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_SOLICITATION_REQUEST };
  }
  function success() {
    return { type: userConstants.CHANGE_PASSWORD_SOLICITATION_SUCCESS };
  }
  function failure() {
    return { type: userConstants.CHANGE_PASSWORD_SOLICITATION_FAILURE };
  }
}

function changePassword(email, password, token) {
  return dispatch => {
    dispatch(request());

    userService.changePassword(email, password, token).then(
      response => {
        dispatch(success());
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            "Não foi possível alterar sua senha. Solicite um novo link de alteração."
          )
        );
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS };
  }
  function failure() {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE };
  }
}

function register(user) {
  return dispatch => {
    dispatch(request(user));
    userService.register(user).then(
      response => {
        dispatch(success());
        dispatch(login(user.email, user.password));
      },
      error => {
        let errorMessage =
          "Não foi possível efetuar o seu cadastro. Verifique as informações e tente novamente.";

        if (error.email) {
          errorMessage = "Já existe um usuário cadastrado com este email.";
        }
        dispatch(failure(errorMessage));
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function updateSelf(user) {
  return dispatch => {
    dispatch(request(user));
    userService.updateSelf(user).then(
      response => {
        dispatch(getSelf());
        dispatch(success());
        dispatch(alertActions.success("Dados atualizados com sucesso!"));
      },
      error => {
        dispatch(failure());

        let errorMessage =
          "Não foi possível atualizar o seu cadastro. Verifique as informações e tente novamente.";

        if (error.email) {
          errorMessage = "Já existe um usuário cadastrado com este email.";
        }

        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getSelf() {
  return dispatch => {
    dispatch(request());

    userService.getSelf().then(
      user => {
        dispatch(success(user.data));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETSELF_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETSELF_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETSELF_FAILURE, error };
  }
}

function getUserById(userId) {
  return dispatch => {
    dispatch(request(userId));

    userService.getById(userId).then(
      user => {
        dispatch(success(user.data));
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request(userId) {
    return { type: userConstants.GET_USER_REQUEST, userId };
  }
  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}
