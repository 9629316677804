import React from "react";
import { Col, Grid, Button, Row, Glyphicon } from "react-bootstrap";
import { connect } from "react-redux";
import TogglePanel from "../../_components/TogglePanel";
import { propertyActions } from "../../_actions/propertyActions";

import {
  toCurrenry,
  renderAddressFormatter,
  renderFormattedListingsStatus,
  renderFormattedListingTooltip,
  isUserAdmin,
  receivingPaymentMethodFormatter,
  receivingContractStatusFormatter,
  tenantFormatter,
  adminPaymentMEthodApprovalRenderer
} from "../../_helpers/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { billingActions } from "../../_actions/billingActions";
import { listingActions } from "../../_actions/listingActions";

import EmptyMessageBlock from "../../_components/EmptyMessageComponent";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { PanelWithTitle } from "../../_components/PanelWithTitle";
import { SimplePanel } from "../../_components/SimplePanel";

import { TooltipWrapper } from "../../_components/TooltipWrapper";
import { PropertyViewDialog } from "./Dialogs/PropertyViewDialog";
import SwitchBox from "../../_components/Shared/SwitchBox";
import {
  ListingApprovalDialog,
  ListingRemovalDialog
} from "./Dialogs/ListingDialogs";
import { PaymentMethodApprovalDialog } from "./Dialogs/PaymentMethodApprovalDialog";
import { userActions } from "../../_actions/userActions";

import {
  showDeleteConfirmationDialog,
  showFoundTenantDialog,
  showPublishingStatusChangeDialog
} from "./Dialogs/AlertDialogs";
import SpinnerComponent from "../../_components/Spinner";

export class NewDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: true,
      _sortOrder: "asc",
      btnGroupValue: "view",
      rentedProperties: props.rentedProperties,
      analyticsGranularity: props.analyticsGranularity
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    if (!this.props.loading) {
      dispatch(listingActions.findAll());
      dispatch(propertyActions.findAll());
      dispatch(billingActions.findAllPayments());
    }
  }
  componentWillMount() {
    document.body.classList.remove("guest-user");
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.rentedProperties) {
      this.setState({
        rentedProperties: nextProps.rentedProperties
      });
    }
    if (nextProps.user) {
      this.setState({
        user: nextProps.user
      });
    }
    if (nextProps.listedProperties) {
      this.setState({
        viewArray: [],
        listings: nextProps.listedProperties
      });
    }
  }
  toogleOpenPanel = index => {
    this.setState({ panelOpen: !this.state.panelOpen, eventkey: index });
  };
  handleSearchUpdated = (eventkey, searchText) => {
    const { dispatch } = this.props;
    dispatch(propertyActions.filterApiPropertiesById(searchText));
  };
  handleBoardAction = (eventCode, id) => {
    switch (eventCode) {
      case "new_boleto_modal":
        this.setState({ boletoModalOpen: true });
        break;
      case "mark_paid_modal":
        this.setState({ markAsPaidModalOpen: true });
        break;
      default:
        break;
    }
  };
  handleMonthSummaryChange = e => {
    const { dispatch, currentDateSelected } = this.props;
    let key = e.target.attributes.eventkey.value;
    switch (key) {
      case "prev":
        dispatch(propertyActions.getSummary(currentDateSelected, -1));
        break;
      case "next":
        dispatch(propertyActions.getSummary(currentDateSelected, 1));
        break;
      default:
        break;
    }
  };
  handleTableChange = (type, newState) => {
    setTimeout(() => {
      const { data, sortField } = newState;
      let result;
      if (this.state._sortOrderListings === "asc") {
        result = data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return 1;
          } else if (b[sortField] > a[sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return -1;
          } else if (b[sortField] > a[sortField]) {
            return 1;
          }
          return 0;
        });
      }

      this.setState(() => ({
        listings: result,
        _sortOrderListings:
          this.state._sortOrderListings === "asc" ? "desc" : "asc"
      }));
    }, 200);
  };
  handlePropertiesTableChange = (type, newState) => {
    setTimeout(() => {
      const { data, sortField } = newState;
      let result;
      if (this.state._sortOrderProperties === "asc") {
        result = data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return 1;
          } else if (b[sortField] > a[sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return -1;
          } else if (b[sortField] > a[sortField]) {
            return 1;
          }
          return 0;
        });
      }

      this.setState(() => ({
        rentedProperties: result,
        _sortOrderProperties:
          this.state._sortOrderProperties === "asc" ? "desc" : "asc"
      }));
    }, 200);
  };
  handleBillingReviewProcess = values => {
    let activeContract = this.props.selectedProperty.contracts.find(
      c => c.is_active
    );
    let activeContractId = activeContract && activeContract.id;
    if (activeContractId) {
      this.props.dispatch(
        propertyActions.reviewBillingMethod(
          activeContractId,
          values,
          this.props.selectedProperty.id
        )
      );
    }
  };
  handleModalHideRequest = () => {
    this.props.dispatch(propertyActions.hideModals());
  };
  handleSwitchChange = (checked, id) => {
    showPublishingStatusChangeDialog(this.props.dispatch, checked, id);
  };
  handleListingApprovalSubmission = values => {
    this.props.dispatch(
      listingActions.reviewListing(
        this.props.selectedProperty.listing.id,
        values
      )
    );
  };
  // handlePropertyBillingStateChange = values => {
  //   const { dispatch, selectedProperty } = this.props;
  //   dispatch(
  //     billingActions.changeContractBillingType(
  //       values.payment_method,
  //       values.id,
  //       selectedProperty.id
  //     )
  //   );
  // };
  render() {
    const {
      currentListingChangeId,
      listingChangeLoading,
      propertyListLoading,
      listedProperties,
      loadingProperties,
      filteringProperties,
      selectedProperty,
      selectedUser,
      showPropertyComponents,
      showListingsComponents,
      rentedProperties,
      listingExclusionConfirmationDialogOpen,
      selectedListing
    } = this.props;

    //Analitics
    // const {
    //   summaryData,
    //   analyticsTableData,
    //   dispatch,
    //   analyticsGranularity,
    //   currentDateSelected,
    // } = this.props;
    // const { btnGroupValue}= this.state;

    // const { user } = this.state;

    const buttonsSizes = "small";
    const propertyTableColumns = [
      {
        dataField: "code",
        text: "ID",
        sort: true
      },
      {
        isDummyField: true,
        dataField: "payment_method",
        text: "Forma Cobrança",
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc")
            return ("" + rowA.value).localeCompare(rowB.value);
          else return ("" + rowB.value).localeCompare(rowA.value);
        },
        formatter: receivingPaymentMethodFormatter
      },
      {
        isDummyField: true,
        hidden: !isUserAdmin(this.props.user),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.props.dispatch(
              billingActions.showPropertyPaymentMethodApprovalModal(row.id)
            );
          }
        },
        formatter: adminPaymentMEthodApprovalRenderer
      },
      {
        isDummyField: true,
        text: "Status Forma Cobrança",
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc")
            return ("" + rowA.value).localeCompare(rowB.value);
          else return ("" + rowB.value).localeCompare(rowA.value);
        },
        formatter: receivingContractStatusFormatter
      },
      {
        dataField: "id",
        text: "Ref Zimobi",
        sort: true,
        hidden:
          this.props.user &&
          this.props.user.data &&
          this.props.user.data.role === "admin"
            ? false
            : true
      },
      {
        dataField: "address",
        sort: true,
        text: "Endereço",
        formatter: (value, row) => {
          return renderAddressFormatter(row.address);
        }
      },
      {
        dataField: "active_contract.tenant",
        text: "Inquilino",
        sort: false,
        formatter: (value, row) => {
          return tenantFormatter(value);
        }
      },
      {
        dataField: "active_contract.due_date_day",
        text: "Dia Vencimento",
        sort: true,
        formatter: (value, row) => {
          return value;
        }
      },
      {
        dataField: "active_contract.total_price_in_cents",
        text: "Valor Total",
        sort: true,
        formatter: (value, row) => {
          return toCurrenry(value ? value / 100 : 0);
        }
      },
      {
        dataField: "df4",
        isDummyField: true,
        formatter: (cellContent, row) => {
          return (
            <Button
              onClick={() =>
                this.props.dispatch(
                  billingActions.showPropertyViewModal(row.id)
                )
              }
              bsSize={buttonsSizes}
              bsStyle={"info"}
            >
              Detalhes
            </Button>
          );
        }
      }
    ];

    const listingColumns = [
      {
        dataField: "property.code",
        text: "Identificação",
        hidden: false,
        sort: true
      },
      {
        dataField: "df3",
        isDummyField: true,
        hidden: !isUserAdmin(this.props.user),
        formatter: (cellContent, row) => {
          let color;
          if (row && row.status) {
            // color = listingsStatusTypes.find(s => s.value === row.status).color;
            color = row.status.color;
          } else {
            color = "gray";
          }
          return (
            <div
              onClick={() => {
                this.props.dispatch(userActions.getUserById(row.user_id));
                this.props.dispatch(
                  billingActions.showPropertyApprovalModal(row.property.id)
                );
              }}
            >
              <TooltipWrapper
                id={"tooltip-" + cellContent}
                tooltip={"Use esta opção para aprovar/rejeitar um anúncio"}
              >
                <Glyphicon
                  glyph="cog"
                  style={{ fontSize: "2rem", cursor: "pointer", color: color }}
                />
              </TooltipWrapper>{" "}
            </div>
          );
        }
      },
      {
        dataField: "status",
        text: "Status",
        hidden: false,
        sort: true,
        style: (colum, colIndex) => {
          return { maxWidth: "150px" };
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc")
            return ("" + rowA._sortingDescription).localeCompare(
              rowB._sortingDescription
            );
          else
            return ("" + rowB._sortingDescription).localeCompare(
              rowA._sortingDescription
            );
        },
        formatter: renderFormattedListingsStatus
      },
      {
        dataField: "statusToolTip",
        isDummyField: true,
        formatter: renderFormattedListingTooltip
      },
      {
        dataField: "is_active",
        isDummyField: true,
        text: "Publicação",
        sort: true,
        formatter: (cellContent, row) => {
          if (row && row.is_data_valid) {
            return currentListingChangeId === row.id && listingChangeLoading ? (
              <SpinnerComponent />
            ) : (
              <div>
                <SwitchBox
                  disabled={
                    (row.status.value === "not_approved" &&
                      row.is_active === false) ||
                    (row.status && row.status.value === "in_review")
                  }
                  id={row.id + ""}
                  onSwitchChange={this.handleSwitchChange}
                  checked={row.is_active}
                />
                <p style={{ marginTop: "10px", fontSize: "12px" }}>
                  {row.is_active
                    ? "Seu anúncio está publicado"
                    : "Seu anúncio NÃO está publicado"}
                </p>
              </div>
            );
          } else {
            return (
              <div>
                <SwitchBox
                  disabled={true}
                  id={row.id + ""}
                  checked={row.is_active}
                />
                <p style={{ marginTop: "10px", fontSize: "12px" }}>
                  {row.is_active
                    ? "Seu anúncio está publicado"
                    : "Seu anúncio NÃO está publicado"}
                </p>
              </div>
            );
          }
        }
      },

      {
        dataField: "property.id",
        text: "Ref Zimobi",
        sort: true,
        hidden: !isUserAdmin(this.props.user)
      },
      {
        dataField: "property.address.street",
        text: "Endereço",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            row && renderAddressFormatter(row.property && row.property.address)
          );
        }
      },
      // {
      //   dataField: 'listing.events.view',
      //   text: (
      //     <Grid fluid={true} style={{ textAlign: 'center', minWidth: '140px' }}>
      //       <Col md={12} xs={12}>
      //         Visualizações
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         Passada
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         {' '}
      //         Atual
      //       </Col>
      //     </Grid>
      //   ),
      //   formatter: (cellContent, row) => {
      //     let view =
      //       row && row.events && row.events.view ? row.events.view : [];
      //     return (
      //       <Grid fluid={true} style={{ textAlign: 'center' }}>
      //         <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //           {view && view[1] ? view[1].count : 0}
      //         </Col>
      //         <Col
      //           md={6}
      //           xs={6}
      //           style={{
      //             fontWeight: 'bold',
      //             opacity: 0.7,
      //             whiteSpace: 'nowrap'
      //           }}
      //         >
      //           <CaretIcon
      //             prev={view && view[1] ? view[1].count : 0}
      //             current={view && view[0] ? view[0].count : 0}
      //           />
      //           {view && view[0] ? view[0].count : 0}
      //         </Col>
      //       </Grid>
      //     );
      //   }
      // },
      // {
      //   dataField: 'listing.events',
      //   text: (
      //     <Grid fluid={true} style={{ textAlign: 'center', minWidth: '140px' }}>
      //       <Col md={12} xs={12}>
      //         Contatos
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         Passada
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         {' '}
      //         Atual
      //       </Col>
      //     </Grid>
      //   ),
      //   formatter: (cellContent, row) => {
      //     let data =
      //       row && row.events && row.events.contact ? row.events.contact : [];
      //     return (
      //       <Grid fluid={true} style={{ textAlign: 'center' }}>
      //         <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //           {data && data[1] ? data[1].count : 0}
      //         </Col>
      //         <Col
      //           md={6}
      //           xs={6}
      //           style={{
      //             fontWeight: 'bold',
      //             opacity: 0.7,
      //             whiteSpace: 'nowrap'
      //           }}
      //         >
      //           {' '}
      //           <CaretIcon
      //             prev={data && data[1] ? data[1].count : 0}
      //             current={data && data[0] ? data[0].count : 0}
      //           />
      //           {data && data[0] ? data[0].count : 0}
      //         </Col>
      //       </Grid>
      //     );
      //   }
      // },
      // {
      //   dataField: 'listing.events.visit_completed',
      //   text: (
      //     <Grid fluid={true} style={{ textAlign: 'center', minWidth: '140px' }}>
      //       <Col md={12} xs={12}>
      //         Visitas Realizadas
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         Passada
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         {' '}
      //         Atual
      //       </Col>
      //     </Grid>
      //   ),
      //   formatter: (cellContent, row) => {
      //     let data =
      //       row && row && row.events && row.events.visit_scheduled
      //         ? row.events.visit_scheduled
      //         : [];
      //     return (
      //       <Grid fluid={true} style={{ textAlign: 'center' }}>
      //         <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //           {data && data[1] ? data[1].count : 0}
      //         </Col>
      //         <Col
      //           md={6}
      //           xs={6}
      //           style={{
      //             fontWeight: 'bold',
      //             opacity: 0.7,
      //             whiteSpace: 'nowrap'
      //           }}
      //         >
      //           {' '}
      //           <CaretIcon
      //             prev={data && data[1] ? data[1].count : 0}
      //             current={data && data[0] ? data[0].count : 0}
      //           />
      //           {data && data[0] ? data[0].count : 0}
      //         </Col>
      //       </Grid>
      //     );
      //   }
      // },
      {
        dataField: "df2",
        isDummyField: true,
        text: "Ações",
        formatter: (cellContent, row) => {
          return (
            row && (
              <div style={{ display: "flex" }}>
                <Button
                  bsSize={buttonsSizes}
                  bsStyle={"primary"}
                  onClick={() =>
                    showFoundTenantDialog(this.props.dispatch, row.property.id)
                  }
                >
                  ENCONTREI MEU INQUILINO
                </Button>
                <Link to={"/listings/edit/" + row.id}>
                  <Button bsSize={buttonsSizes} bsStyle={"info"}>
                    Editar
                  </Button>
                </Link>
                {row.property && (
                  <Link
                    to={"/listings/view/" + row.property.id}
                    target="_blank"
                    style={{
                      display:
                        row && row && row.is_data_valid && row.is_active
                          ? ""
                          : "none"
                    }}
                  >
                    <Button bsSize={buttonsSizes} bsStyle={"info"}>
                      Ver anúncio
                    </Button>
                  </Link>
                )}
                <Button
                  bsSize={buttonsSizes}
                  bsStyle={"default"}
                  onClick={() =>
                    showDeleteConfirmationDialog(this.props.dispatch, row.id)
                  }
                >
                  Excluir anúncio
                </Button>
              </div>
            )
          );
        }
      }
    ];

    const ListingsTableList = ({ items, isFiltering, columns }) => {
      return !isFiltering && items.length === 0 ? (
        <EmptyMessageBlock
          title={"Você ainda não cadastrou nenhum anúncio"}
          subtitle={"Anuncie e coloque seu imóvel visível a todos na rede"}
          callToActionLabel="Novo Anúncio"
          callToActionSize={"sm"}
          imagePath={"/images/icon_ad_listing.svg"}
          linkTo={"/listings/new"}
        />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <BootstrapTable
            key={"listings_table"}
            id="listings_table"
            striped
            bordered={false}
            noDataIndication="Sem dados"
            keyField="id"
            data={items.slice(0, 5)}
            columns={columns}
            headerClasses={"content-table"}
            rowClasses={"content-table"}
          />
          <div className="text-center">
            <Link to={"/listings"}>
              <Button bsSize={buttonsSizes} bsStyle={"info"}>
                Ver a lista completa
              </Button>
            </Link>
            <Link to={"/listings/new"}>
              <Button bsSize={"small"} bsStyle={"primary"}>
                CRIAR NOVO ANÚNCIO
              </Button>
            </Link>
          </div>
        </div>
      );
    };
    const PropertyTableList = ({ columns, items, isFiltering }) => {
      return !isFiltering && items.length === 0 && !loadingProperties ? (
        <EmptyMessageBlock
          title={"Você ainda não cadastrou nenhuma propriedade."}
          subtitle={
            "Começe a ter o controle de suas propriedades através do botão abaixo"
          }
          callToActionLabel="Novo Imóvel"
          callToActionSize={"sm"}
          imagePath={"/images/icon_houses.svg"}
          linkTo={"/properties/new"}
        />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <BootstrapTable
            id="properties_table"
            key={"properties_table"}
            striped
            bordered={false}
            noDataIndication="Sem dados"
            keyField="id"
            data={items.slice(0, 5)}
            columns={columns}
            headerClasses={"content-table"}
            rowClasses={"content-table"}
          />
          <div className="text-center">
            <Link to={"/properties"}>
              <Button bsSize={buttonsSizes} bsStyle={"info"}>
                Ver a lista completa
              </Button>
            </Link>
            <Link to={"/properties/new"}>
              <Button bsSize={"small"} bsStyle={"primary"}>
                CRIAR NOVO IMÓVEL
              </Button>
            </Link>
          </div>
        </div>
      );
    };

    let panels = [
      {
        thinBody: true,
        body: (
          <div>

          </div>
        )
      },
      {
        hidden: showPropertyComponents || showListingsComponents,
        thinBody: true,
        body: propertyListLoading ? (
          <SimplePanel>
            <LoadingMessage />
          </SimplePanel>
        ) : (
          <div>
            <h4
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              className=" text-center"
            >
              Olá{" "}
              {this.props.user &&
                this.props.user.data &&
                this.props.user.data.full_name.substr(
                  0,
                  this.props.user.data.full_name.indexOf(" ")
                )}
              , você não possui nenhum imóvel cadastrado.
              <br />O que você gostaria de fazer?
            </h4>
            <Col md={6}>
              <PanelWithTitle
                iconPath={"/images/icon_ad_listing.svg"}
                to={"/listings/new"}
                callToActionSize={"small"}
                callToAction={"Criar Anúncio"}
                title={"Cadastrar Anúncio"}
                body={"Anunciamos seu imóvel nos melhores portais gratuitamente."}
              />
            </Col>
            <Col md={6}>
              <PanelWithTitle
                bsStyle={"info"}
                iconPath={"/images/icon_houses.svg"}
                to={"/properties/new"}
                callToAction={"Cadastrar Imóvel"}
                callToActionSize={"small"}
                title={"Emitir Cobranças"}
                body={"Escolha entre receber manualmente ou receber por boleto bancário."}
              />
            </Col>
          </div>
        )
      },
      // {
      //   hidden: !showPropertyComponents,
      //   thinBody: true,
      //   body: <h4>Visão Geral - Imóveis Alugados</h4>
      // },
      // {
      //   body: (
      //     <div>
      //       <Col md={3} sm={12}>
      //         <KPI
      //           title={'Total Mensal'}
      //           value={
      //             summaryData && summaryData.completed_amount_in_cents
      //               ? toCurrenry(summaryData.completed_amount_in_cents / 100)
      //               : toCurrenry(0)
      //           }
      //           subtitle={'Recebido'}
      //           color={'#2DEF56'}
      //         />
      //       </Col>
      //       <Col
      //         md={5}
      //         sm={12}
      //         xsHidden={false}
      //         smHidden={true}
      //         mdHidden={true}
      //         lgHidden={true}
      //       >
      //         <DonutChartKPI summaryData={summaryData} padding={{ right: 0 }} />{' '}
      //       </Col>
      //       <Col
      //         md={5}
      //         sm={12}
      //         xsHidden={true}
      //         smHidden={false}
      //         mdHidden={false}
      //         lgHidden={false}
      //       >
      //         <DonutChartKPI summaryData={summaryData} padding={{ right: 0 }} />{' '}
      //       </Col>
      //       <Col md={4} sm={12}>
      //         <KPI
      //           title={'Total Previsto'}
      //           value={
      //             summaryData && summaryData.total_amount_in_cents
      //               ? toCurrenry(summaryData.total_amount_in_cents / 100)
      //               : toCurrenry(0)
      //           }
      //           subtitle={`Mensais de ${
      //             summaryData ? summaryData.properties_count : 0
      //           } imóveis`}
      //           color={'#5EC7CE'}
      //         />
      //       </Col>
      //     </div>
      //   ),
      //   toogleable: false,
      //   hidden: !showPropertyComponents,
      //   title: 'Resumo das cobranças',
      //   customComponent: (
      //     <div style={{ minWidth: '100px' }} className="title">
      //       {' '}
      //       <Glyphicon
      //         eventkey={'prev'}
      //         onClick={this.handleMonthSummaryChange}
      //         style={{ marginRight: '2rem', cursor: 'pointer' }}
      //         glyph="glyphicon glyphicon-menu-left"
      //       />{' '}
      //       <span>
      //         {moment.monthsShort()[moment(currentDateSelected).month()]}/
      //         {moment(currentDateSelected).year()}
      //       </span>{' '}
      //       <Glyphicon
      //         eventkey={'next'}
      //         onClick={this.handleMonthSummaryChange}
      //         style={{ marginLeft: '2rem', cursor: 'pointer' }}
      //         glyph="glyphicon glyphicon-menu-right"
      //       />
      //     </div>
      //   )
      // },
      {
        body:
          rentedProperties && !loadingProperties ? (
            <PropertyTableList
              columns={propertyTableColumns}
              isFiltering={filteringProperties}
              loadingProperties={loadingProperties}
              items={rentedProperties.data}
            />
          ) : (
            <LoadingMessage />
          ),
        loading: loadingProperties,
        title: "Imóveis Alugados",
        hidden: !showPropertyComponents,
        toogleable: false,
        hasSearch: false
      },
      // {
      //   thinBody: true,
      //   hidden: !showListingsComponents,
      //   body: <h4>Visão Geral - Imóveis Livres</h4>
      // },
      // {
      //   title: 'Número de imóveis anunciados',
      //   hidden: !showListingsComponents,
      //   body: (
      //     <div style={{ minHeight: '230px' }}>
      //       <ThinKPI
      //         value={listedProperties && listedProperties.meta.total_count}
      //         color={'#61c7cf'}
      //       />
      //     </div>
      //   ),
      //   colSize: 4
      // },
      // {
      //   title: 'Resumo dos anúncios',
      //   hidden: !showListingsComponents,
      //   customComponent: (
      //     <DropdownButton
      //       id={2}
      //       style={{
      //         borderColor: '#f0f0f0',
      //         borderRadius: '16px',
      //         marginBottom: '5px'
      //       }}
      //       className="title "
      //       bsStyle={'default'}
      //       bsSize={'small'}
      //       title={
      //         analyticsGranularity && analyticsGranularity.selectedoptiontitle
      //           ? analyticsGranularity.selectedoptiontitle
      //           : 'Resumo Semanal'
      //       }
      //       key={1}
      //       onSelect={(evtKey, evt) => {
      //         dispatch(
      //           propertyActions.setAnalyticsGranularity({
      //             selectedoptiontitle:
      //               evt.target.attributes.selectedoptiontitle.value,
      //             previousprefixsummaryname:
      //               evt.target.attributes.previousprefixsummaryname.value,
      //             summaryname: evt.target.attributes.summaryname.value,
      //             granularity: evt.target.attributes.eventkey.value
      //           })
      //         );
      //       }}
      //     >
      //       <MenuItem
      //         eventkey="week"
      //         selectedoptiontitle="Resumo Semanal"
      //         previousprefixsummaryname="Última"
      //         summaryname=" Semana"
      //         active={
      //           analyticsGranularity &&
      //           analyticsGranularity.granularity === 'week'
      //         }
      //       >
      //         Resumo Semanal
      //       </MenuItem>
      //       <MenuItem
      //         eventkey="month"
      //         selectedoptiontitle="Resumo Mensal"
      //         previousprefixsummaryname="Último"
      //         summaryname=" Mês"
      //         active={
      //           analyticsGranularity &&
      //           analyticsGranularity.granularity === 'month'
      //         }
      //       >
      //         Resumo Mensal
      //       </MenuItem>
      //     </DropdownButton>
      //   ),
      //   body: analyticsTableData ? (
      //     <div style={{ minHeight: '230px' }}>
      //       <Col md={12} lg={6}>
      //         <div className="text-center">
      //           <ButtonToolbar>
      //             <ToggleButtonGroup
      //               className="listing-summary"
      //               type="radio"
      //               name="options"
      //               defaultValue={btnGroupValue}
      //             >
      //               {toggleButtons.map((button, index) => {
      //                 return (
      //                   <ToggleButton
      //                     key={index}
      //                     bsSize="xs"
      //                     style={{ margin: '1px' }}
      //                     bsStyle={
      //                       btnGroupValue === button.key
      //                         ? 'primary-color'
      //                         : 'primary-color-disabled'
      //                     }
      //                     value={button.key}
      //                     onClick={() =>
      //                       this.setState({ btnGroupValue: button.key })
      //                     }
      //                   >
      //                     {button.value}
      //                   </ToggleButton>
      //                 );
      //               })}
      //             </ToggleButtonGroup>
      //           </ButtonToolbar>
      //           {analyticsTableData && analyticsTableData.data && (
      //             <GetAnalyticsDataChart
      //               btnGroupValue={btnGroupValue}
      //               analyticsGranularity={analyticsGranularity}
      //               content={analyticsTableData.data}
      //             />
      //           )}
      //         </div>
      //       </Col>
      //       <Col md={12} lg={6}>
      //         <div style={{ overflowX: 'auto', textAlign: 'center' }}>
      //           {analyticsTableData && (
      //             <GetAnalyticsDataTable
      //               analyticsGranularity={analyticsGranularity}
      //               data={analyticsTableData.data}
      //             />
      //           )}
      //           {analyticsTableData.lastUpdate && (
      //             <small
      //               style={{
      //                 width: '100%',
      //                 float: 'right',
      //                 textAlign: 'center'
      //               }}
      //             >
      //               Última atualização:{' '}
      //               {analyticsTableData.lastUpdate.format('DD/MMM/YYYY')}
      //             </small>
      //           )}
      //         </div>
      //       </Col>
      //     </div>
      //   ) : (
      //     <LoadingMessage />
      //   ),
      //   colSize: 8
      // },
      {
        body:
          listedProperties && !propertyListLoading ? (
            <ListingsTableList
              items={listedProperties.data}
              columns={listingColumns}
            />
          ) : (
            <LoadingMessage />
          ),
        title: "Imóveis Anunciados",
        hidden: !showListingsComponents,
        toogleable: false,
        colSize: 12
      }
    ];

    return (
      <div>
        {selectedProperty && (
          <ListingApprovalDialog
            onHide={this.handleModalHideRequest}
            onSubmit={this.handleListingApprovalSubmission}
            container={this}
            user={selectedUser}
            property={selectedProperty}
            open={this.props.propertyToApproveOpen}
          />
        )}
        {listingExclusionConfirmationDialogOpen && (
          <ListingRemovalDialog
            onHide={this.handleModalHideRequest}
            onSubmit={this.handleListingRemovalSubmission}
            container={this}
            listing={selectedListing}
            open={listingExclusionConfirmationDialogOpen}
          />
        )}
        {selectedProperty && selectedUser && (
          <PaymentMethodApprovalDialog
            onHide={this.handleModalHideRequest}
            onSubmit={this.handleBillingReviewProcess}
            container={this}
            property={selectedProperty}
            user={selectedUser}
            open={this.props.propertyPaymentToApproveOpen}
          />
        )}
        {selectedProperty && (
          <PropertyViewDialog
            onHide={this.handleModalHideRequest}
            container={this}
            loading={this.props.billingChangeInProgress}
            // handleSubmit={this.handlePropertyBillingStateChange}
            property={this.props.selectedProperty}
            show={this.props.propertyViewModalOpen}
          />
        )}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Row>
                {panels.map((panel, index) => {
                  return (
                    !panel.hidden && (
                      <TogglePanel
                        key={index}
                        body={panel.body}
                        eventkey={index}
                        form={this.props.form}
                        handleValidationChange={
                          this.handlePanelValidationChange
                        }
                        initialValues={panel.initialValues}
                        isExpanded={
                          panel.isExpanded ||
                          (this.state.panelOpen &&
                            this.state.eventkey === index)
                        }
                        onBackButtonClick={this.goToPreviousPanel}
                        onSubmit={this.goToNextPanel}
                        showNextButton
                        title={panel.title}
                        toogleOpenPanel={() => this.toogleOpenPanel(index)}
                        validate={this.props.validate}
                        size={panel.colSize}
                        thinBody={panel.thinBody}
                        toogleable={panel.toogleable}
                        hasSearch={panel.hasSearch}
                        onSearchChanged={this.handleSearchUpdated}
                        customComponent={panel.customComponent}
                      />
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  const { properties } = state;
  const { rentedProperties, listedProperties } = properties;
  return {
    user: state.authentication.user,
    selectedUser: state.registration.selectedUser,
    rentedProperties: rentedProperties,
    currentListingChangeId: properties.currentListingChangeId,
    listingChangeLoading: properties.listingChangeLoading,
    propertyListLoading: properties.propertyListLoading,
    loadingProperties: properties.loadingProperties,
    listedProperties: listedProperties,
    currentDateSelected: properties.currentDateSummary,
    summaryData: properties.summaryData,
    filteringProperties: properties.filteringProperties,
    analyticsTableData: properties.analyticsTableData,
    billingChangeInProgress: properties.billingChangeInProgress,
    analyticsGranularity: properties.analyticsGranularity,
    propertyViewModalOpen: properties.propertyViewModalOpen,
    propertyToApproveOpen: properties.propertyToApproveOpen,
    listingExclusionConfirmationDialogOpen:
      properties.listingExclusionConfirmationDialogOpen,
    propertyPaymentToApproveOpen: properties.propertyPaymentToApproveOpen,
    selectedProperty: properties.selectedProperty,
    showPropertyComponents:
      rentedProperties && rentedProperties.data && rentedProperties.data.length,
    showListingsComponents:
      listedProperties && listedProperties.data && listedProperties.data.length,
    selectedListing: properties.selectedListing
  };
};
export default connect(mapStateToProps)(NewDashboardPage);
