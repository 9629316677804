import React from "react";
export class LoginContainer extends React.Component {
  componentDidMount() {
    window.location.replace(
        `${process.env.REACT_APP_FRONTEND3_URL}/app/home`
      );
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}

export default LoginContainer;
