import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions/userActions';
import { history } from '../_helpers/history';
import {
  Image,
  Navbar,
  Nav,
  NavDropdown,
  MenuItem,
  Glyphicon
} from 'react-bootstrap';
export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  logout() {
    this.props.dispatch(userActions.logout());
    history.push('/dashboard');
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // alert('You clicked outside of me!');
      this.setState({ isOpen: false });
    }
  }

  render() {
    return (
      <div className="headerWrapper" ref={this.setWrapperRef}>
        <Navbar
          collapseOnSelect
          expand="lg"
          fixedTop
          style={{ backgroundColor: 'white' }}
          expanded={this.state.isOpen}
          onToggle={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <Navbar.Header>
            <Navbar.Brand>
              <Image className="logo" src="/images/logo.png" />
            </Navbar.Brand>
            {this.props.loggedIn && <Navbar.Toggle />}
          </Navbar.Header>

          <Navbar.Collapse>
            <Nav pullRight>
              {this.props.loggedIn ? (
                <NavDropdown
                  eventKey={1}
                  title={
                    <div className="pull-left">
                      <span>{this.props.userFullName}</span>
                      <Image
                        style={{ height: '60px' }}
                        circle
                        src={
                          this.props.userAvatarUrl ||
                          '/images/avatar_placeholder.png'
                        }
                      />
                    </div>
                  }
                  id="basic-nav-dropdown"
                  className="nav-item"
                >
                  <MenuItem eventkey={3.2} onSelect={() => this.logout()}>
                    Sair (Desconectar)
                  </MenuItem>
                </NavDropdown>
              ) : (
                <MenuItem eventkey={3.1} onClick={() => history.push('/login')}>
                  Entrar{' '}
                  <Glyphicon
                    style={{ color: '#2DEF56' }}
                    glyph={'glyphicon glyphicon-triangle-top'}
                  />
                </MenuItem>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    userFullName:
      state.authentication.user && state.authentication.user.data
        ? state.authentication.user.data.full_name
        : '',
    userAvatarUrl:
      state.authentication.user &&
      state.authentication.user.data &&
      state.authentication.user.data.avatar_url
        ? state.authentication.user.data.avatar_url
        : '/images/user_profile.jpg'
  };
}

export default connect(mapStateToProps)(Header);
