import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import alert from "../_reducers/alert";
import authentication from "../_reducers/authentication";
import registration from "../_reducers/registration";
import properties from "../_reducers/properties";
import bankAccounts from "../_reducers/bankAccounts";
import landlords from "../_reducers/landlords";
import payments from "../_reducers/payments";
import { createLogger } from "redux-logger";
import { alertActions } from "../_actions/alertActions";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { history } from "../_helpers/history";

// Apply the middleware to the store
const middleware = routerMiddleware(history);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const FetchInterceptor = require("fetch-interceptor");
const reduxLogger = createLogger({
  diff: true,
  collapsed: true
});

// eslint-disable-next-line
const interceptor = FetchInterceptor.register({
  onBeforeRequest(request, controller) {},
  onRequestSuccess(response, request, controller) {},
  onRequestFailure(response, request, controller) {
    if (response.status === 401 || response.status === 403) {
      alertActions.error("Acesso Negado");
    }
  }
});

export default () => {
  const rootReducer = combineReducers({
    alert,
    authentication,
    bankAccounts,
    landlords,
    registration,
    properties,
    routerReducer,
    payments,
    form: formReducer
  });

  const buildStore = () => {
    if (process.env.NODE_ENV !== "production") {
      if (module.hot) {
        return createStore(
          rootReducer,
          composeEnhancers(
            applyMiddleware(thunk, reduxLogger),
            applyMiddleware(middleware)
          )
        );
      }
    } else {
      return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk), applyMiddleware(middleware))
      );
    }
  };

  const store = buildStore();

  return store;
};
